import React, { useEffect, useState } from 'react'
import { IonContent, IonHeader, IonItemGroup, IonPage } from '@ionic/react'
import {
  clearIntervalAsync,
  setIntervalAsync,
} from 'set-interval-async/dynamic'
import './defaultpage.scss'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../config/app.config'
import { connectDefaultPage } from './defaultpage.connect'
import Toolbar from '../../components/Toolbar/Toolbar'
import ToolbarDesktop from '../../components/ToolbarDesktop/ToolbarDesktop'
import Promotion from '../../components/Promotion/promotion'
import InPlayPopular from '../../components/InPlay/inPlayPopular'
import { headerType } from '../../helpers/InPlayPopular.helper'
import LegalAgePopover from '../../components/LegalAgePopover/LegalAgePopover'
import KycPopover from '../../components/KycPopover/KycPopover'
import MoreRulesPopover from '../../components/MoreRulesPopover/MoreRulesPopover'
import { useHistory } from 'react-router'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import BannerPopUp from '../../components/BannerPopUp/BannerPopUp'
import { useDispatch, useSelector } from 'react-redux'
import LeftSideMenuDesktop from '../../components/LeftSideMenuDesktop/LeftSideMenuDesktop'
import BalanceInformation from '../../components/BalanceInformation/BalanceInformation'
import BetSettingsDesktop from '../../components/BetSettingsDesktop/BetSettingsDesktop'
import BetSlipDesktop from '../../components/BetSlipDesktop/BetSlipDesktop'
import BetPlacing from '../../components/BetPlacing/BetPlacing'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'
import whatsAppImg from '../../assets/images/whatsApp.svg'
import WhatsappBanner from '../WhatsappBanner/WhatsappBanner'
import { isSignedUpWithNumberInBranchHelper } from '../../helpers/isSignedUpWithNumberInBranch.helper'
import Bonuses from '../bonuses/Bonuses'
import BonusBanner from '../bonuses/BonusBanner'
import BonusCard from '../../components/BonusCard/BonusCard'
import { requestFortuneWheel } from '../../store/fortuneWheel/fortuneWheel.thunks'
import Verification from '../../components/Verification/Verification'
import VerifButtons from '../../components/Verification/VerifButtons'
import ExchangeGamesGridList from '../../components/ExchangeGamesGridList/ExchangeGamesGridList'
import { ExchangeGamesData } from '../../constants/Casino/ExchangeGamesData'
import leftSideMenuMapper from '../../helpers/getLeftSideMenuMapper.helper'
import CasinoGridList from '../../components/CasinoGridList/CasinoGridList'
import { CasinoProviderDATA } from '../../constants/Casino/CasinoProviderData'
import { requestLiveCasinoLobby } from '../../store/liveCasino/liveCasino.thunks'
import { IndianCasino } from '../../constants/Casino/IndainCasino'

const DefaultPage = ({
  auth,
  lastActivityDate,
  inplayPopular,
  promotions,
  balanceSettings,
  legalAgePopoverShow,
  kycPopoverShow,
  moreRulesPopoverShow,
  timeSettings,
  betPlacing,
  orderCount,
  actions,
  elementsParams,
  fortuneWheel,
  liveCasino,
  isNavigationName,
  B2C,
  ...props
}) => {
  const history = useHistory()
  const [displayedBack, setDisplayedBack] = useState(false)
  const bannerOpen = useSelector((state) => state.bannerPopUp)
  const dispatch = useDispatch()
  // let pathname = history.location.pathname
  const pathname = props.localPathnameNow

  useEffect(() => {
    if (history.location.pathname === '/default-page' && auth)
      actions.requestOrderCount()
    actions.setThisEvent(false)
    actions.requestLiveCasinoLobby()
    actions.requestOpenBets()
    actions.requestUnMatchedBets()
    // if (history.location.pathname === '/') actions.requestOrderCount();
  }, [actions, lastActivityDate])

  useEffect(() => {
    actions.requestLiveCasinoLobby()
    actions.requestOpenBets()
    actions.requestUnMatchedBets()
  }, [auth])

  const getInplayPopular = () => {
    actions
      .requestInplayPopular()
      .catch((e) => console.log('requestInplayPopular error = ', e))
  }
  // const getPromotion = () => {
  // actions.requestPromotions().catch(e => console.log('requestInplayPopular error = ', e))
  // }

  // useEffect(() => {
  // getPromotion()
  //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // console.log("def page inplayPopular", inplayPopular)

  useEffect(() => {
    // if (!inplayPopular && history.location.pathname === '/') getInplayPopular()
    if (!inplayPopular && history.location.pathname === '/default-page')
      getInplayPopular()
    else {
      let timer
      if (auth) {
        timer = setIntervalAsync(async () => {
          // if (history.location.pathname === '/') getInplayPopular()
          if (history.location.pathname === '/default-page')
            await getInplayPopular()
          // else if (inplayPopular) actions.getInplayPopularSuccess(null)
        }, GET_INPLAY_POPULAR_INTERVAL)
      } else {
        timer = setIntervalAsync(async () => {
          // if (history.location.pathname === '/') getInplayPopular()
          if (history.location.pathname === '/default-page')
            await getInplayPopular()
          // else if (inplayPopular) actions.getInplayPopularSuccess(null)
        }, 60000)
      }
      return () => clearIntervalAsync(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inplayPopular, history.location.pathname])

  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

  const [renderFooter, setRenderFooter] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (window.location.pathname.includes('login')) {
        actions.setLoginNew(true)
      }

      setRenderFooter(true)
    }, 1500)
  }, [])

  useEffect(() => {
    if (window?.location?.pathname?.includes('register')) {
      setTimeout(() => {
        // window.history.pushState({},"", "/");
        actions.setLoginNew(true)
        actions.setTab(2)
      }, 500)
    }
  }, [])

  let userData
  useEffect(() => {
    userData = JSON.parse(localStorage.getItem('user_data'))
    if (width >= 1024) {
      window.history.pushState(null, document.title)
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title)
      })
    }
    if (history.location.pathname === '/default-page' && auth)
      actions.requestOpenBets()
      actions.requestUnMatchedBets()
  }, [history.location.pathname])

  useEffect(() => {
    if (auth) {
      dispatch(requestFortuneWheel('70001', 'FAWK'))
    }
  }, [auth])

  let betLength = orderCount && orderCount
  // console.log(
  //   "homeMatchesOpen list for casinoGames on homePAge item.sport_id == `-100'",
  //   inplayPopular && inplayPopular.filter((item) => item.sport_id === '-100')
  // )
  // sport_id: "-100"

  const getMenu = (data) => {
    actions.requestMenu(data).catch((e) => console.log('menu error = ', e))
  }

  const openGame = (sport_id) => {
    actions.getMenuSuccess([])
    {
      if (sport_id === 'QT' || sport_id === '-100') {
        actions.setSlotCasinoPopUp(true)
        actions.requestCasinoPoint()
      }

      sport_id !== '1444001' &&
        'QTech' !== isNavigationName &&
        actions.setIsNavigationOpen(true)
      actions.setRenderIonContent(false)
      // actions.setIsAnimatedMenu(true)
      setTimeout(() => {
        actions.setIsNavigationOpen(false)
      }, 400)
      setTimeout(() => {
        actions.setIsAnimatedMenu(false)
      }, 400)
      getMenu({ sport_id: sport_id })
      actions.setIsNavigationID(sport_id)
      actions.setIsNavigationName('QTech')
      actions.setIsMoreDetailed('')
      actions.setIsDetailedGroup('')
    }

    if (width < 1024) {
      let page = `/menu-event/${sport_id}`
      history.push({
        pathname: page,
        state: { id: sport_id },
      })
    } else {
      history.push('/sports-page')
    }
  }

  return (
    <>
      {width < 1024 ? (
        <>
          {bannerOpen && <BannerPopUp />}
          <IonPage>
            <IonHeader>
              <Toolbar inplayPopular={inplayPopular} />
            </IonHeader>
            <IonContent className="default-page__content">
              <Promotion />
              {<BonusBanner />}
              {isSignedUpWithNumberInBranchHelper() ? (
                <WhatsappBanner
                  link={JSON.parse(localStorage.getItem('user_data'))?.wa}
                />
              ) : B2C ?(
                <VerifButtons auth={auth} actions={actions} />
              ) : null}
              <Verification />
              {/*{<BonusCard/>}*/}
              {/*{<Verification />}*/}
              {inplayPopular && inplayPopular ? (
                <>
                  <InPlayPopular
                    pathname={pathname}
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={
                      inplayPopular &&
                      inplayPopular.filter((item) => item.inplay)
                    }
                    type={headerType['INPLAY']}
                    orderCount={(betLength && betLength.length) || 0}
                    promotions={promotions}
                  />
                  {liveCasino && liveCasino.length ? (
                    <IonItemGroup className="inplay-content">
                      <ExchangeGamesGridList
                        type={headerType['POPULAR']}
                        itemHeaderData={leftSideMenuMapper['Popular Games']}
                        itemsData={liveCasino}
                        openGame={(id) => {
                          actions.requestLiveCasinoID(id)
                          history.push(`/live_casino_slot/${id}`)
                        }}
                      />
                    </IonItemGroup>
                  ) : (
                    <IonItemGroup className="inplay-content">
                      <ExchangeGamesGridList
                        type={headerType['POPULAR']}
                        itemHeaderData={leftSideMenuMapper['Popular Games']}
                        itemsData={IndianCasino}
                        openGame={(id) => {
                          actions.requestLiveCasinoID(id)
                          history.push(`/live_casino_slot/${id}`)
                        }}
                      />
                    </IonItemGroup>
                  )}

                  <IonItemGroup className="inplay-content">
                    <CasinoGridList
                      type={headerType['POPULAR']}
                      itemHeaderData={leftSideMenuMapper['Casino']}
                      itemsData={CasinoProviderDATA}
                      openGame={() => openGame('QT')}
                    />
                  </IonItemGroup>

                  <InPlayPopular
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={
                      inplayPopular &&
                      inplayPopular.filter((item) => !item.inplay)
                    }
                    type={headerType['POPULAR']}
                  />
                </>
              ) : (
                <LoaderContent />
              )}
              {fortuneWheel?.url && auth && (
                <iframe
                  className="default-page__content-iframe"
                  src={fortuneWheel?.url}
                ></iframe>
              )}

              <LegalAgePopover open={legalAgePopoverShow} />
              <KycPopover open={kycPopoverShow} />
              <MoreRulesPopover open={moreRulesPopoverShow} />
              {renderFooter && <FooterDesktop />}
              {/* <div style={{position: 'absolute', left: '10px', bottom: '20px', zIndex: '11111'}}>
              <img src={whatsAppImg} alt="" />
             </div> */}
            </IonContent>
          </IonPage>
        </>
      ) : (
        <>
          {bannerOpen && <BannerPopUp />}
          <div style={{ height: '100%' }}>
            <div className="default-page__content">
              <Promotion />
              {auth && <BonusBanner />}
              {isSignedUpWithNumberInBranchHelper() && (
                <WhatsappBanner
                  link={JSON.parse(localStorage.getItem('user_data'))?.wa}
                />
              )}
              {inplayPopular && inplayPopular ? (
                <>
                  <InPlayPopular
                    pathname={pathname}
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={
                      inplayPopular &&
                      inplayPopular.filter((item) => item.inplay)
                    }
                    type={headerType['INPLAY']}
                    orderCount={(betLength && betLength.length) || 0}
                    promotions={promotions}
                  />
                  {liveCasino && liveCasino.length ? (
                    <IonItemGroup className="inplay-content">
                      <ExchangeGamesGridList
                        type={headerType['POPULAR']}
                        itemHeaderData={leftSideMenuMapper['Popular Games']}
                        itemsData={liveCasino}
                        openGame={(id) => {
                          actions.requestLiveCasinoID(id)
                          history.push(`/live_casino_slot/${id}`)
                        }}
                      />
                    </IonItemGroup>
                  ) : (
                    <IonItemGroup className="inplay-content">
                      <ExchangeGamesGridList
                        type={headerType['POPULAR']}
                        itemHeaderData={leftSideMenuMapper['Popular Games']}
                        itemsData={IndianCasino}
                        openGame={(id) => {
                          actions.requestLiveCasinoID(id)
                          history.push(`/live_casino_slot/${id}`)
                        }}
                      />
                    </IonItemGroup>
                  )}

                  <IonItemGroup className="inplay-content">
                    <CasinoGridList
                      type={headerType['POPULAR']}
                      itemHeaderData={leftSideMenuMapper['Casino']}
                      itemsData={CasinoProviderDATA}
                      openGame={() => openGame('QT')}
                    />
                  </IonItemGroup>

                  <InPlayPopular
                    displayedBack={displayedBack}
                    betPlacing={betPlacing}
                    timeSettings={timeSettings}
                    setDisplayedBack={setDisplayedBack}
                    balanceSettings={
                      balanceSettings && balanceSettings.settings
                    }
                    inPlayEvents={
                      inplayPopular &&
                      inplayPopular.filter((item) => !item.inplay)
                    }
                    type={headerType['POPULAR']}
                  />
                </>
              ) : (
                <LoaderContent />
              )}

              <LegalAgePopover open={legalAgePopoverShow} />
              <KycPopover open={kycPopoverShow} />
              <MoreRulesPopover open={moreRulesPopoverShow} />
              {fortuneWheel?.url && auth && (
                <iframe
                  className="default-page__content-iframe"
                  src={fortuneWheel?.url}
                ></iframe>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default connectDefaultPage()(DefaultPage)
