import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setUserDataSuccess } from '../store/user/user.slice'
import { getAuthError, getAuthSuccess } from '../store/auth/auth.slice'
import { requestAuthVerification, requestLogout, whatsAppReq } from '../store/auth/auth.thunks'
import { setAuthDataSuccess } from '../store/auth/authData.slice'
import { setContinueSessionModalShow } from '../store/modals/continueSessionModal.slice'
import { requestBalance } from '../store/balance/balance.thunks'
import { requestBalanceSettings } from '../store/balanceSettings/balanceSettings.thunks'
import { requestMenu } from '../store/menu/menu.thunks'
import { requestMessage } from '../store/message/message.thunks'
import { setShowBetPlacingPopover } from '../store/betPlacing/betPlacing.slice'
import { requestOpenBets } from '../store/openBets/openBets.thunks'
import { setLoginNew } from '../store/LoginNew/loginNew.slice'
import { mainContainerHeightThunk, scrollPositionThunk, setClientWidthThunk } from './elementsParams/getElementsParams.thunk'
import { setSearchValues } from './navigationHeaderMenu/navigationHeaderMenu.slice'
import { setBannerPopUp } from './bannerPopUp/bannerPopUp.slice'
import { confirmMailVerification } from './profile/profile.thunks'
import { requestProfile } from './profile/profile.thunks'
import { version } from 'process'

export const connectApp = (wrappedProps) => (component) => {
    function mapStateToProps(state) {
        return {
            state: state,
            auth: state.auth,
            authData: state.authData,
            balance: state.balance,
            menu: state.menu,
            message: state.message,
            user: state.user,
            lastActivityDate: state.balance.lastActivityDate,
            continueSessionModalShow: state.continueSessionModalShow,
            showBetPlacingPopover: state.betPlacing.showBetPlacingPopover,
            search: state.search,
            loginNew: state.loginNew,
            balanceInformationHeight: state.balanceInformationHeight,
            isPopUpOpenDispatch: state.editStakesPopUp.isOpen,
            isConfirmBetPopUpOpen: state.betPlacing.isConfirmBetPopUpOpen,
            rulesPopUp: state.rulesPopUp,
            elementsParams: state.elementsParams,
            banners: state.promotions,
            version: state.version,
            androidVersion: state.androidVersion,
            promotions: state.promotions.socials
        }
    }

    function mapDispatchToProps(dispatch) {
        const actions = {
            getAuthError,
            getAuthSuccess,
            setAuthDataSuccess,
            setUserDataSuccess,
            setContinueSessionModalShow,
            setShowBetPlacingPopover,
            requestLogout,
            requestBalance,
            requestBalanceSettings,
            requestMenu,
            requestMessage,
            requestOpenBets,
            setLoginNew,
            mainContainerHeightThunk,
            scrollPositionThunk,
            setClientWidthThunk,
            setSearchValues,
            setBannerPopUp,
            whatsAppReq,
            confirmMailVerification,
            requestProfile,
            requestAuthVerification
        }

        return { actions: bindActionCreators(actions, dispatch) }
    }

    return connect(mapStateToProps, mapDispatchToProps)(component)
}
