import {io} from 'socket.io-client';
import { BASE_URL } from '../config/api.config';
import { ID_TOKEN } from '../config/app.config';

const localToken = localStorage.getItem(`${ID_TOKEN}`)

export const Socket = io(BASE_URL, {
    transports: ['websocket'],
    query: {
      access_token: localToken,
    },
  });


Socket.on('connect_error', () => {
    console.log("Connection Error");
    Socket.io.opts.transports = ['websocket'];
});

Socket.on('disconnect', () => {
    Socket.emit('connected');
})

Socket.on("connect", () => {
    console.log("Connected");
    Socket.sendBuffer = [];
});
