import './inplayitem.scss'
import React, { useEffect, useState } from 'react'
import { IonImg, IonItem } from '@ionic/react'
import OddButton from '../OddButton/oddbutton'
import BetPlacing from '../BetPlacing/BetPlacing'
import broadcastImg from '../../assets/images/inplay-broadcast-icon.svg'
import { headerType } from '../../helpers/InPlayPopular.helper'
import { getFormattedDate } from '../../helpers/date.helper'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
  addfavoritesItem,
  clearfavoritesItem,
  addfavoritesItemPop,
  clearfavoritesItemPop,
  addFavoritesAll,
  clearFavoritesAll,
} from '../../store/favorites/favorites.slice'

import StarIcon from '../../assets/images/sideBarStar.svg'
import StarIconFilled from '../../assets/images/sideBarStarFilled.svg'
import InPlayItemLoaderPoUp from './InPlayItemLoaderPoUp/InPlayItemLoaderPoUp'

import { setIsOpenSport } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setIsSportsGroupName } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setIsMoreDetailed } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setIsEventDetailed } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
import { setIsAnimatedMenu } from '../../store/navigationHeaderMenu/navigationHeaderMenu.slice'
// import SportMarketStandard from '../SportMarketStandard/SportMarketStandard'

const InPlayItem = ({
  type,
  itemData,
  timeSettings,
  displayedBack,
  setDisplayedBack,
  betPlacing,
}) => {
  const history = useHistory()
  const [item, setItem] = useState(null)
  const [touchStart, setTouchStart] = useState(0)
  const [touchEnd, setTouchEnd] = useState(0)
  const [openPopUp, setOpenPopUp] = useState(false)

  const checkStateWidth = useSelector((state) => state.elementsParams.clientWidth)
  const width = checkStateWidth ? checkStateWidth : window.innerWidth

  useEffect(() => {
    setItem(itemData)
  }, [itemData])
  // console.log("itemDATA", itemData)
  const handleTouchStart = (e) => {
    setTouchStart(e.targetTouches[0].clientX)
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX)
  }

  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 1) {
      setDisplayedBack(true)
    }

    if (touchStart - touchEnd < -1) {
      setDisplayedBack(false)
    }

    setTouchEnd(0)
    setTouchStart(0)
  }

  const gotoEvent = () => {
    localStorage.setItem("Event", JSON.stringify(item));
    
    if (item.eventTypeId == '77777')
      history.push(`/casino_live/${item.event.id}`)
    else {
      history.push(`/event-page/${item.sport_id}/${item.match_id}`)
      if (width < 1024) {
        setOpenPopUp(true)
      }
    }
    // else history.push(`/event-page/${itemData.eventTypeId}/30286579`) // event with stats and scorecard
  }

  useEffect(() => {
    setTimeout(() => {
      setOpenPopUp(false)
    }, 600);
  }, [openPopUp]);


  // const items = useSelector((state) => state.inplayPopular)

  const getOdds = () => {
    if (!item) return

    const status = item.status
    const odds = item.runners
    const firstOdds = item.runners[0]?.ex
    const firstRunner = item.runners[0]
    // availableToBack[0]
    // availableToLay[0]
    const secondOdds = item.runners[1]?.ex
    const secondRunner = item.runners[1]
    const thirdOdds = item.runners[2]?.ex
    const thirdRunner = item.runners[2]
    const back = odds?.back
    const lay = odds?.lay

    if (status && !!status.localeCompare('OPEN')) {
      return (
        <div
          className={`inplay-item__back-inner ${
            displayedBack
              ? 'inplay-item__status-inner-right'
              : 'inplay-item__status-inner-left'
          }`}
          onTouchMove={(e) => handleTouchMove(e)}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchEnd={(e) => handleTouchEnd(e)}
        >
          <div className="inplay-item__status">{status.toUpperCase()}</div>
        </div>
      )
    }

    if (width < 1024) {
      return (
        <div
          className={`inplay-item__back-inner ${
            displayedBack
              ? 'inplay-item__back-inner-right'
              : 'inplay-item__back-inner-left'
          }`}
          onTouchMove={(e) => handleTouchMove(e)}
          onTouchStart={(e) => handleTouchStart(e)}
          onTouchEnd={(e) => handleTouchEnd(e)}
        >
          {/*{*/}
          {/*  itemData && itemData.eventTypeId == "2" */}
          {/*    ?*/}
          {/*    <>*/}
          {/*      {item.market && back && back[0] && back[0].price && <OddButton market={item.market} isBack={true} oddData={back && back[0]} centered/>}*/}
          {/*      {item.market && back && back[1] && back[1].price && <OddButton market={item.market} isBack={true} oddData={back && back[1]} centered/>}*/}
          {/*      {item.market && back && back[2] && back[2].price && <OddButton market={item.market} isBack={true} oddData={back && back[2]} centered/>}*/}
          {/*      {item.market && lay && lay[0] && lay[0].price && <OddButton market={item.market} isBack={false} oddData={lay && lay[0]} centered/>}*/}
          {/*      {item.market && lay && lay[1] && lay[1].price && <OddButton market={item.market} isBack={false} oddData={lay && lay[1]} centered/>}*/}
          {/*      {item.market && lay && lay[2] && lay[2].price && <OddButton market={item.market} isBack={false} oddData={lay && lay[2]} centered/>}*/}
          {/*    </>*/}
          {/*    :*/}
              <>
                <OddButton market={item} isBack={true} oddData={{...firstOdds && firstOdds.availableToBack[0], ...firstRunner}} centered/>
                <OddButton market={item} isBack={true} oddData={{...thirdOdds && thirdOdds.availableToBack[0], ...thirdRunner}} centered/>
                <OddButton market={item} isBack={true} oddData={{...secondOdds && secondOdds.availableToBack[0], ...secondRunner}} centered/>
                <OddButton market={item} isBack={false} oddData={{...firstOdds && firstOdds.availableToLay[0], ...firstRunner}} centered/>
                <OddButton market={item} isBack={false} oddData={{...thirdOdds && thirdOdds.availableToLay[0], ...thirdRunner}} centered/>
                <OddButton market={item} isBack={false} oddData={{...secondOdds && secondOdds.availableToLay[0], ...secondRunner}} centered/>
              </>
          {/*}*/}
        </div>
      )
    }

    return (
      <div
        className={`inplay-item__back-inner ${
          displayedBack
            ? 'inplay-item__back-inner-right'
            : 'inplay-item__back-inner-left'
        }`}
        onTouchMove={(e) => handleTouchMove(e)}
        onTouchStart={(e) => handleTouchStart(e)}
        onTouchEnd={(e) => handleTouchEnd(e)}
      >
        {
          itemData && itemData.eventTypeId == "2"
          ?
            // <>
            //   {item.market && back && back[0] && back[0].price && <OddButton market={item.market} isBack={true} oddData={back && back[0]} centered/>}
            //   {item.market && back && back[1] && back[1].price && <OddButton market={item.market} isBack={true} oddData={back && back[1]} centered/>}
            //   {item.market && back && back[2] && back[2].price && <OddButton market={item.market} isBack={true} oddData={back && back[2]} centered/>}
            //   {item.market && lay && lay[0] && lay[0].price && <OddButton market={item.market} isBack={false} oddData={lay && lay[0]} centered/>}
            //   {item.market && lay && lay[1] && lay[1].price && <OddButton market={item.market} isBack={false} oddData={lay && lay[1]} centered/>}
            //   {item.market && lay && lay[2] && lay[2].price && <OddButton market={item.market} isBack={false} oddData={lay && lay[2]} centered/>}
            // </>
            <>
              {/*{item.market && back && back[0] && back[0].price && <OddButton market={item.market} isBack={true} oddData={back && back[0]} centered/>}*/}
              {/*{item.market && lay && lay[0] && lay[0].price && <OddButton market={item.market} isBack={false} oddData={lay && lay[0]} centered/>}*/}
              {/*/!*{item.market && back && back[2] && back[2].price && <OddButton market={item.market} isBack={true} oddData={back && back[2]} centered/>}*!/*/}
              {/*/!*{item.market && lay && lay[2] && lay[2].price && <OddButton market={item.market} isBack={false} oddData={lay && lay[2]} centered/>}*!/*/}
              {/*{item.market && back && back[1] && back[1].price && <OddButton market={item.market} isBack={true} oddData={back && back[1]} centered/>}*/}
              {/*{item.market && lay && lay[1] && lay[1].price && <OddButton market={item.market} isBack={false} oddData={lay && lay[1]} centered/>}*/}

              {<OddButton market={item} isBack={true} oddData={back && back[0]} centered/>}
              {<OddButton market={item} isBack={false} oddData={lay && lay[0]} centered/>}
              {<OddButton market={item} isBack={true} oddData={back && back[2]} centered/>}
              {<OddButton market={item} isBack={false} oddData={lay && lay[2]} centered/>}
            </>
          :
            // <>
            //   <OddButton market={item.market} isBack={true} oddData={back && back[0]} centered/>
            //   <OddButton market={item.market} isBack={true} oddData={back && back[1]} centered/>
            //   <OddButton market={item.market} isBack={true} oddData={back && back[2]} centered/>
            //   <OddButton market={item.market} isBack={false} oddData={lay && lay[0]} centered/>
            //   <OddButton market={item.market} isBack={false} oddData={lay && lay[1]} centered/>
            //   <OddButton market={item.market} isBack={false} oddData={lay && lay[2]} centered/>
            // </>
            <>
              <OddButton market={item} isBack={true} oddData={{...firstOdds && firstOdds.availableToBack[0], ...firstRunner}} centered/>
                <OddButton market={item} isBack={true} oddData={{...thirdOdds && thirdOdds.availableToBack[0], ...thirdRunner}} centered/>
                <OddButton market={item} isBack={true} oddData={{...secondOdds && secondOdds.availableToBack[0], ...secondRunner}} centered/>
                <OddButton market={item} isBack={false} oddData={{...firstOdds && firstOdds.availableToLay[0], ...firstRunner}} centered/>
                <OddButton market={item} isBack={false} oddData={{...thirdOdds && thirdOdds.availableToLay[0], ...thirdRunner}} centered/>
                <OddButton market={item} isBack={false} oddData={{...secondOdds && secondOdds.availableToLay[0], ...secondRunner}} centered/>
            </>
        }
      </div>
    )
  }

  const getName = () => {
    if (!item) return
    const players = item.match_name.split(" v ")
    // console.log("item.match_name.split(v)", item.match_name.split("v"))

    // if (!players) {
    //   return <div className="inplay-item__players" onClick={() => {
    //     gotoEvent()
    //   }}></div>
    // }

    const scoresPlayers = item.scores && item.scores.players
    const hasVideo = item?.has_tv_url
    return (
      <div className="inplay-item__players" onClick={gotoEvent}>
        {players.map((p, index) => (
          <span key={index} className="inplay-item__player">
            {scoresPlayers &&
              (scoresPlayers[index].batting ||
                scoresPlayers[index].serving) && (
                <span className="inplay-item__player-batting"></span>
              )}
            {p}
          </span>
        ))}
        {/* {item.match_name} */}
        {hasVideo && (
          <IonImg className="inplay-item__broadcast-icon" src={broadcastImg} />
        )}
      </div>
    )
  }

  const getScore = () => {
    switch (type) {
      case headerType['INPLAY']: {
        return getInplayScore()
      }

      case headerType['POPULAR']: {
        return getPopularScore()
      }

      default:
        return
    }
  }

  const getPopularScore = () => {
    if (!item) return

    const openDate = item.match_date
    if (!openDate) {
      return getNoScore()
    }

    const formattedOpenDate = getFormattedDate(
      openDate,
      timeSettings.timeZoneName
    )

    return (
      <div className="date-content">
        <span className="date-content__date">{formattedOpenDate.date}</span>
        <span className="date-content__time">{formattedOpenDate.time}</span>
      </div>
    )
  }

  const getNoScore = () => {
    const openDate = item.match_date
    if (!openDate) {
      return <div className="score-content empty">
      <div className="date-content">
        <span className="inPlayDate-content__date"></span>
        <span className="inPlayDate-content__time"></span>
      </div>
    </div>
    }

    const formattedOpenDate = getFormattedDate(
      openDate,
      timeSettings.timeZoneName
    )
    return <div className="score-content empty">
      <div className="date-content">
        <span className="inPlayDate-content__date">{formattedOpenDate.date}</span>
        <span className="inPlayDate-content__time">{formattedOpenDate.time}</span>
      </div>
    </div>
  }

  const getInplayScore = () => {
    if (!item) return

    const scores = item.scores
    if (!scores) {
      return getNoScore()
    }

    switch (Number(item.eventTypeId)) {
      case 1: {
        // const penalty = scores.penalty;
        // TODO refactor
        const fulTime = Math.ceil(scores.time / 60000)
        const time = fulTime / 90 > 1 ? 90 : fulTime
        const extraTime = fulTime % 90

        const team1Score = scores.players[0].score
        const team2Score = scores.players[1].score

        return (
          <div className="score-content football">
            <span className="score-content__football-time">
              {time === 45 ? 'HT' : `${time}’`}
            </span>
            {fulTime >= 90 && (
              <span className="score-content__football-time">
                + {extraTime}’
              </span>
            )}
            <span className="score-content__football-score-group">
              <span>{team1Score}</span>
              <span>{team2Score}</span>
            </span>
          </div>
        )
      }

      case 2: {
        const team1Won = scores.players[0].setsWon
        const team2Won = scores.players[1].setsWon
        const team1Score = scores.players[0].currentScore
        const team2Score = scores.players[1].currentScore
        return (
          <div className="score-content football">
            <span className="score-content__football-score-group won">
              <span>{team1Won}</span>
              <span>{team2Won}</span>
            </span>
            <span className="score-content__football-score-group">
              <span>{team1Score}</span>
              <span>{team2Score}</span>
            </span>
          </div>
        )
      }

      case 4: {
        const overs = scores.overs
        const target = scores.target
        const wickets = scores.wickets
        const score = scores.score
        const currentInning = scores.currentInning

        return (
          <div className="score-content cricket">
            <span>{overs} Ov</span>
            {currentInning === 2 && <span>Tgt {target}</span>}
            <span>
              {score}/{wickets}
            </span>
          </div>
        )
      }

      case 77777: {
        return
      }

      case 1444001: {
        return
      }

      default:
        return
    }
  }

  //TODO: open betslip defaultPage

  const renderBetPlacing = () => {
    // console.log("item", item)
    const status = item?.GameStatus || item?.display_message || item?.status;
    const statusLabel = item?.statusLabel; 
    // console.log("item", item, "status", status, "item.market.id", item?.marketId, "!status.localeCompare('OPEN')", !status?.localeCompare('OPEN'))
    if (
      betPlacing.market === item?.marketId &&
      // betPlacing.runner === runner.id &&
      status &&
      !status.localeCompare('OPEN') &&
      width < 1024
    ){
      console.log("in betplacing")

      return <BetPlacing />
    }
    return null
  }

  const items = useSelector((state) => state.inplayPopular)
  const favoritesState = useSelector((state) => state.favorites)
  // const sportData = useSelector((state) => state.sportEvent.sportData)
  // console.log('sportData', sportData)

  localStorage.setItem('favorites', JSON.stringify(favoritesState))
  // const storagedFavorites = JSON.parse(localStorage.getItem('favorites'))

  // const isFavorite = () => {
  //   if (
  //     favoritesState?.inPlay?.length > 0 &&
  //     item &&
  //     item?.event &&
  //     favoritesState?.inPlay?.filter((el) => el?.event?.id === item?.event?.id)
  //       .length
  //   ) {
  //     return true
  //   } else if (
  //     favoritesState?.popular?.length > 0 &&
  //     item &&
  //     item?.event &&
  //     favoritesState?.popular?.filter((el) => el?.event?.id === item?.event?.id)
  //       ?.length
  //   ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }
    const isFavorite = () => {
    if (
      favoritesState?.favoritesAll?.length > 0 &&
      item &&
      favoritesState?.favoritesAll?.filter((el) => el?.id === item?.market?.id).length
    ) {
      return true
    } else {
      return false
    }
  }

  const [activeIcon, setActiveIcon] = useState([])

  const toggleStar = (id) => {
    setActiveIcon((prevActiveIcon) => {
      // Check if the id is already in the array
      if (prevActiveIcon.includes(id)) {
        // Remove the id from the array
        return prevActiveIcon.filter((iconId) => iconId !== id);
      } else {
        // Add the id to the array
        return [...prevActiveIcon, id];
      }
    });
  };

  // const toggleStar = () => {

  //   setActiveIcon((prevCheck) => !prevCheck)
  // }

  const dispatch = useDispatch()

  useEffect(() => {
    const matchIds = favoritesState?.favoritesAll?.map(favorite => favorite.market_id) || [];
    setActiveIcon(matchIds)
    // console.log("favoritesState", favoritesState?.favoritesAll)
    // if (isFavorite()) {
    //   setActiveIcon(true)
    // } else setActiveIcon(false)
  }, [favoritesState, item])

  const handleFavorite = (id, item) => {
    if (activeIcon.includes(id)) {
      // console.log('if active clear')
      // if (item.inplay) {
        dispatch(clearfavoritesItem(item))
      // } else {
      //   dispatch(clearfavoritesItemPop(item))
      // }
      // dispatch(clearFavoritesAll(item))
    } else {
      console.log('else add', item)
      // if (item?.inplay) {
        dispatch(addfavoritesItem(item))
      // } else {
      //   dispatch(addfavoritesItemPop(item))
      // }
      // dispatch(addFavoritesAll(item))
    }
  }

  return (
    <>
      {width < 1024 ? (
        <>
          <div className="inplay-item">
            <div className="inplay-item__content">
              <div className="inplay-item__row">
                <div className="inplay-item__score" onClick={gotoEvent}>
                  {getScore()}
                </div>
                {getName()}
                <div className="inplay-item__back">
                  <div className="inplay-item__back-inner">{getOdds()}</div>
                </div>
              </div>
              {renderBetPlacing()}
            </div>
          </div>
          {openPopUp &&
            <InPlayItemLoaderPoUp
              onDidDismiss={() => setOpenPopUp(false)}
          />}
        </>
      ) : (
        <>
          <IonItem className="inplay-item">
            {history.location.pathname === '/favouritesPage' ? (
              <img
                onClick={() => {
                  // if (item.isInPlay) {
                  //   dispatch(clearfavoritesItem(item))
                  // } else {
                  //   dispatch(clearfavoritesItemPop(item))
                  // }
                  dispatch(clearFavoritesAll(item?.market))
                }}
                loading={"lazy"}
                className="starImg"
                src={StarIconFilled}
                alt="star icon"
              />
            ) : (
              <img
                onClick={() => {
                  console.log("Item Data", item)
                  toggleStar(item?.market_id)
                  handleFavorite(item.market_id, item)
                }}
                className="starImg"
                src={activeIcon.includes(item?.market_id) ? StarIconFilled : StarIcon}
                alt="star icon"
                loading={"lazy"}
              />
            )}
            <div className="inplay-item__content">
              <div className="inplay-item__row">
                <div className="inplay-item__desktop">
                  {!item?.isInPlay && (
                    <div className="inplay-item__score" onClick={gotoEvent}>
                      {getScore()}
                    </div>
                  )}

                  {getName()}
                </div>

                <div className="inplay-item__back">
                  <div className="inplay-item__back-inner">{getOdds()}</div>
                </div>
              </div>
              {renderBetPlacing()}
            </div>
          </IonItem>
        </>
      )}
    </>
  )
}

export default InPlayItem
