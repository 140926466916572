import React, { useEffect, useRef, useState } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import {
  useIonViewWillEnter,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonImg,
} from '@ionic/react'
import './ExchangeGamePage.scss'
// import { connectEventPage } from './eventpage.connect'
import Toolbar from '../../components/Toolbar/Toolbar'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Timeline } from 'react-twitter-widgets'
import RaceListEventDetails from '../../components/RaceListEventDetails'
import SportEvent from '../../components/SportEvent/SportEvent'
import LiveSection from '../../components/LiveSection/LiveSection'
import OpenBets from '../../components/OpenBets/OpenBets'
import { RaceEventDropdownList } from '../../components/RaceEventDropdown'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import { formatTime } from '../../helpers/date.helper'
import {
  GET_STATS_INTERVAL,
  GET_MARKET_DATA_INTERVAL,
} from '../../config/app.config'
import ToolbarDesktop from '../../components/ToolbarDesktop/ToolbarDesktop'
import LeftSideMenuDesktop from '../../components/LeftSideMenuDesktop/LeftSideMenuDesktop'
import BalanceInformation from '../../components/BalanceInformation/BalanceInformation'
import BetSettingsDesktop from '../../components/BetSettingsDesktop/BetSettingsDesktop'
import BetSlipDesktop from '../../components/BetSlipDesktop/BetSlipDesktop'
import broadcastImg from '../../assets/images/inplay-logo-iconSport.svg'
import { connectExchangeGamePage } from './exchangeGamePage.connect'
import  Roulette  from '../../components/ExchangeGame/Roulette/Roulette'
import TwoCardTeenPatti from '../../components/ExchangeGame/TwoCardTeenPatti/TwoCardTeenPatti'
import Queen from '../../components/ExchangeGame/Queen/Queen'
import Trio from '../../components/ExchangeGame/Trio/Trio'
import TeenpattiTest from '../../components/ExchangeGame/TeenpattiTest/TeenpattiTest'
import Trap from '../../components/ExchangeGame/Trap/Trap'
import Sicbo from '../../components/ExchangeGame/Sicbo/Sicbo'
import Poker20 from '../../components/ExchangeGame/Poker20/Poker20'
import MuflisTeenpatti from '../../components/ExchangeGame/MuflisTeenpatti/MuflisTeenpatti'
import LiveTeenpatti from '../../components/ExchangeGame/LiveTeenpatti/LiveTeenpatti'
import TeenpattiT20 from '../../components/ExchangeGame/TeenpattiT20/TeenpattiT20'
import Baccarat from '../../components/ExchangeGame/Baccarat/Baccarat'
import CasinoMeter from '../../components/ExchangeGame/CasinoMeter/CasinoMeter'
import Race20 from '../../components/ExchangeGame/Race20/Race20'
import BollywoodCasino from '../../components/ExchangeGame/BollywoodCasino/BollywoodCasino'
import Casino32card from '../../components/ExchangeGame/Casino32card/Casino32card'
import Poker from '../../components/ExchangeGame/Poker/Poker'
import HiLow from '../../components/ExchangeGame/HiLow/HiLow'
import AndarBahar from '../../components/ExchangeGame/AndarBahar/AndarBahar'
import AmarAkbarAnthony from '../../components/ExchangeGame/AmarAkbarAnthony/AmarAkbarAnthony'
import SevenUpDown from '../../components/ExchangeGame/7UpDown/SevenUpDown'
import DragonTiger from '../../components/ExchangeGame/DragonTiger/DragonTiger'
import Matka from '../../components/ExchangeGame/Matka/Matka'
import SixPlayerPoker from '../../components/ExchangeGame/SixPlayerPoker/SixPlayerPoker'
import CasinoWar from '../../components/ExchangeGame/CasinoWar/CasinoWar'
import { headerHeight } from '../../store/elementsParams/getElementsParams.thunk'
import RulesPopUp from '../../components/ExchangeGame/helpers/Rules/RulesPopUp'
import BetPlacing from '../../components/BetPlacing/BetPlacing'


const ExchangeGamePage = ({
                     actions,
                     lastActivityDate,
                     twitterSettings,
                     sportEventStats,
                     openBets,
                     // exposure,
                     orders,
                     sportData,
                     rulesPopUp,
                     // raceOdds,
                     elementsParams,
                     betPlacing
                   }) => {
  const history = useHistory()

  const { eventTypeId, eventId } = useParams()
  const [activeTab, setActiveTab] = useState(0)
  const [contentScrolled, setContentScrolled] = useState(false)
  const [style, setStyle] = useState({})
  const [racingEventHeader, setRacingEventHeader] = useState(null);
  const [heightForBetslip, setHeightForBetslip] = useState();

  console.log("eventId", eventId)

  const onScroll = (e) => {
    setHeightForBetslip(e.detail.scrollTop)
    if (e.detail.deltaY < 0 || e.detail.scrollTop <= 120) {
      if (contentScrolled) setContentScrolled(false)
    } else if (e.detail.deltaY > 0) {
      if (!contentScrolled && e.detail.scrollTop > 120) setContentScrolled(true)
    }
  }

  const isRacing = eventTypeId == 7 || eventTypeId == 4339
  const isLoaded = isRacing
    ? true
    : ((sportData?.[0]?.event?.id === eventId &&
        sportData?.[0]?.eventTypeId === eventTypeId) ||
      sportData?.[0]?.groupById === eventId) &&
    sportEventStats?.eventId === eventId &&
    sportEventStats?.eventTypeId === eventTypeId

  const sportTabs = [
    {
      label: 'market',
      setTab: () => setActiveTab(0),
    },
    {
      label: 'news',
      setTab: () => setActiveTab(1),
    },
    sportEventStats &&
    (sportEventStats.hasVideo || sportEventStats.showVisualisation)
      ? {
        label: 'live',
        icon: <span className="LiveSection__tv-icon"></span>,
        setTab: () => setActiveTab(2),
      }
      : undefined,
    {
      label: `open bets (${(orders && orders.count) || '0'})`,
      setTab: () => setActiveTab(3),
    },
  ]

  const raceTabs = [
    {
      label: 'market',
      setTab: () => setActiveTab(0),
    },
    {
      label: 'news',
      setTab: () => setActiveTab(1),
    },
    undefined,
    {
      label: `open bets (${(orders && orders.count) || '0'})`,
      setTab: () => setActiveTab(3),
    },
  ]

  useEffect(() => {
    if (
      history.location.pathname.indexOf(
        `/exchange-game-page/${eventTypeId}/${eventId}`
      ) !== -1
    )
      actions.requestSportStart(eventId)
    if (
      history.location.pathname.indexOf(
        `/exchange-game-page/${eventTypeId}/${eventId}`
      ) !== -1 &&
      activeTab == 3
    )
      actions.requestOpenBets(eventId)
      actions.requestUnMatchedBets(eventId)
  }, [eventId, eventTypeId, history.location.pathname, lastActivityDate])

  useEffect(() => {
    if (
      history.location.pathname.indexOf(
        `/exchange-game-page/${eventTypeId}/${eventId}`
      ) !== -1
    )
      setActiveTab(2)
  }, [eventTypeId, eventId, history.location.pathname])

  useEffect(() => {
    if (
      (isRacing ||
        (sportEventStats &&
          !sportEventStats.hasVideo &&
          !sportEventStats.showVisualisation)) &&
      activeTab === 2
    )
      setActiveTab(0)
  }, [sportEventStats, activeTab, eventTypeId])

  useEffect(() => {
    if (activeTab == 1) actions.requestTwitterSettings()
    if (activeTab == 3) actions.requestOpenBets(eventId)
    if (activeTab == 3) actions.requestUnMatchedBets(eventId)
  }, [activeTab])

  /**
   * sport event
   */
  useEffect(() => {
    const mainTimer = setIntervalAsync(async() => {
      if (
        history.location.pathname.indexOf(
          `/exchange-game-page/${eventTypeId}/${eventId}`
        ) !== -1
      ) {

        if (!isRacing) {
          await actions.requestSportData(eventTypeId, eventId)}
      }
    }, GET_MARKET_DATA_INTERVAL)
    return () => clearIntervalAsync(mainTimer)
  }, [eventTypeId, eventId, history.location.pathname])

  useEffect(() => {
    if (!isRacing) {
      if (
        history.location.pathname.indexOf(
          `/exchange-game-page/${eventTypeId}/${eventId}`
        ) !== -1
      ) {
        actions.requestSportStats(eventTypeId, eventId)
      }
      const mainTimer = setIntervalAsync(async() => {
        if (
          history.location.pathname.indexOf(
            `/exchange-game-page/${eventTypeId}/${eventId}`
          ) !== -1
          ) {
            await actions.requestSportStats(eventTypeId, eventId)
          }
      }, GET_STATS_INTERVAL)
      return () => clearIntervalAsync(mainTimer)
    }
  }, [eventTypeId, eventId, history.location.pathname])

  useIonViewWillEnter(async () => {
    actions.getSportEventError()
    actions.getSportStatsError()
    if (!isRacing) {
      await Promise.all([
        actions.requestSportData(eventTypeId, eventId),
        actions.requestSportStats(eventTypeId, eventId),
      ])
    }
  })
  /**
   *
   */


  const renderPageContent = (e) => {
    if (isLoaded) {
      // console.log('isLoaded', isLoaded)
      if (isRacing)
        return (
          <div className="market eventPage-racing">
            <RaceEventDropdownList oddId={eventId} isOpen={true} full showPageHeader={true} setRacingEventHeader={setRacingEventHeader} showJockeyName={true}/>
            <IonLabel className="EventPage__title">Upcoming races</IonLabel>
            <RaceListEventDetails
              exclude={eventId}
              eventsShow={5}
              eventTypeId={eventTypeId}
              showJockeyName={true}
            />
          </div>
        )
      if ((eventId && eventId == '98788') || (eventId && eventId == '98792') ) {
        return <Roulette eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if (eventId && eventId == '67660') {
        return <TwoCardTeenPatti eventTypeId={eventTypeId} eventId={eventId}/>
      }
      if (eventId && eventId == '67620') {
        return <Queen eventTypeId={eventTypeId} eventId={eventId}/>
      }
      if (eventId && eventId == '67610') {
        return <Trio eventTypeId={eventTypeId} eventId={eventId}/>
      }
      if (eventId && eventId == '67630') {
        return <TeenpattiTest eventTypeId={eventTypeId} eventId={eventId}/>
      }
      if (eventId && eventId == '67680') {
        return <Trap eventTypeId={eventTypeId} eventId={eventId}/>
      }
      if (eventId && eventId == '98566') {
        return <Sicbo eventTypeId={eventTypeId} eventId={eventId}/>
      }
      if (eventId && eventId == '67567') {
        return <Poker20 eventTypeId={eventTypeId} eventId={eventId}/>
      }
      if (eventId && eventId == '67600') {
        return <MuflisTeenpatti eventTypeId={eventTypeId} eventId={eventId}/>
      }
      if ((eventId && eventId == '56767') || (eventId && eventId == '56766')) {
        return <LiveTeenpatti eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if ((eventId && eventId == '56768') || (eventId && eventId == '56769')) {
        return <TeenpattiT20 eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if ((eventId && eventId == '67575') || (eventId && eventId == '67575')) {
        return <CasinoMeter eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if (eventId && eventId == '92038') {
        return <Baccarat eventTypeId={eventTypeId} eventId={eventId}/>
      }
      if ((eventId && eventId == '90100') || (eventId && eventId == '90101')) {
        return <Race20 eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if (eventId && eventId == '67570') {
        return <BollywoodCasino eventTypeId={eventTypeId} eventId={eventId}/>
      }
      if ((eventId && eventId == '56967') || (eventId && eventId == '56966')) {
        return <Casino32card eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if ((eventId && eventId == '67563') || (eventId && eventId == '67564')){
        return <Poker eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if (eventId && (eventId == '56968' || eventId == '56969')) {
        return <HiLow heightForBetslip={heightForBetslip} eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if (eventId && (eventId == '87564' || eventId == '87565')) {
        return <AndarBahar eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if (eventId && (eventId == '98791' || eventId == '98795')) {
        return <AmarAkbarAnthony eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if (eventId && (eventId == '98789' || eventId == '98793')) {
        return <SevenUpDown eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if ((eventId && eventId == '98790') || (eventId && eventId == '98794')){
        return <DragonTiger eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if (eventId && (eventId == '92036' || eventId == '92037')) {
        return <Matka eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if (eventId && (eventId == '67565' || eventId == '67566')) {
        return <SixPlayerPoker eventTypeId={eventTypeId} eventId={eventId} activeTab={activeTab}/>
      }
      if (eventId && eventId == '67580') {
        return <CasinoWar eventTypeId={eventTypeId} eventId={eventId}/>
      }
      return <SportEvent eventTypeId={eventTypeId} eventId={eventId} />
    }

    return <LoaderContent />
  }

  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const hasVideo = sportData && sportData[0]?.inPlay

  // get header position
  const refExchangeGamePageHeader = useRef(null)

  const headerHeight = elementsParams.headerHeight

    useEffect(() => {
      actions.headerHeightThunk(
          width < 1024
            ? refExchangeGamePageHeader?.current?.attributes?.style?.value
            : "")
    }, [headerHeight, refExchangeGamePageHeader && refExchangeGamePageHeader?.current?.attributes?.style?.value ])
  // end get header position

  const timeZoneName = useSelector((state) => state.timeSettings.timeZoneName)
  const getZonedTime = (timezone, time) => {
    let date = time ? new Date(time) : new Date()
    if (timezone === 'system')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    if (timezone === 'india')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 330)

    return date
  }
  const tsToDate = (ts, timezone) => {
    const date = getZonedTime(timezone, ts)

    return `${
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate()
    }/${
      (date.getMonth() + 1).toString().length === 1
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${formatTime(
      date.getMinutes()
    )}:${formatTime(date.getSeconds())}`
  }
  const startDate = new Date(sportData && sportData[0]?.event?.openDate)

  const optimizedDate = tsToDate(startDate, timeZoneName)

  const renderBetPlacing = () => {
    if (betPlacing.runner && width < 1024) {
      return <BetPlacing  />
    }
    return null
  }

  useEffect(() => {
    console.log('closed', history.location.pathname)
    actions.setMarket(null)
    actions.setRunner(null)
    actions.setIsBack(null)
    actions.setPrice(null)
    actions.setPriceActual(null)
    actions.setStake(null)
}, [history.location.pathname])
  return (
    <>
      {width < 1024 ? (
        <IonPage>
          <IonHeader className="onscrollHeader " style={style} ref={refExchangeGamePageHeader}>
            <Toolbar
              contentScrolled={contentScrolled}
              tabs={isLoaded ? (isRacing ? raceTabs : sportTabs) : false}
              activeTab={activeTab}
              eventHeader={isLoaded}
              setStyle={setStyle}
            />
            <>{ width < 1024 ? renderBetPlacing() : '' }</>
          </IonHeader>

          <IonContent
            className="ExchangeGamePage"
            fullscreen
            onIonScroll={onScroll}
            scrollEvents={true}
          >
            {activeTab === 2 && isLoaded ? (
              <LiveSection
                eventTypeId={eventTypeId}
                eventId={eventId}
                historyVideo={history.location.pathname}
              />
            ) : null}
            {(activeTab === 0 || activeTab === 2) && renderPageContent()}
            {activeTab === 1 && (
              <div className="news">
                {twitterSettings && (
                  <Timeline
                    dataSource={{
                      sourceType: twitterSettings['4'].sourceType,
                      slug: twitterSettings['4'].slug,
                      ownerScreenName: twitterSettings['4'].ownerScreenName,
                    }}
                    options={{
                      ...twitterSettings['4'].options,
                      tweetLimit: false,
                    }}
                  />
                )}
              </div>
            )}
            {activeTab === 3 && <OpenBets openBets={openBets} isEvent={true} />}
          </IonContent>
        </IonPage>
      ) : (
        <div
          className="ExchangeGamePage"
          fullscreen
          onIonScroll={onScroll}
          scrollEvents={true}
        >
          {/*<div style={{ marginBottom: '15px' }} className="favourites-header">*/}
          <div style={{ marginBottom: '15px' }} className={`favourites-header ${isRacing ? "racing-header" : ""}`}>
            {hasVideo && (
              <IonImg
                className="inplay-item__broadcast-icon"
                src={broadcastImg}
              />
            )}
            <h1 style={{ marginLeft: '12px' }}>
              { racingEventHeader || sportData && sportData[0]?.event?.name}
            </h1>
            <div className={`favourites-header__rules-button-wrapper`}>
              <div className={`favourites-header__rules-button`} onClick={() => {actions.setRulesPopUp(true)}}>
                <div className={`favourites-header__rules-button-icon`}>
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 0C3.1402 0 0 3.14024 0 7.00004C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7.00004C14 3.14024 10.8598 0 7 0ZM7 12.7273C3.84194 12.7273 1.27273 10.1581 1.27273 7.00004C1.27273 3.84202 3.84194 1.27273 7 1.27273C10.1581 1.27273 12.7273 3.84202 12.7273 7.00004C12.7273 10.1581 10.158 12.7273 7 12.7273Z" fill="black"/>
                    <path d="M7.00017 2.96875C6.5324 2.96875 6.15186 3.34955 6.15186 3.81762C6.15186 4.28526 6.5324 4.66572 7.00017 4.66572C7.46794 4.66572 7.84849 4.28526 7.84849 3.81762C7.84849 3.34955 7.46794 2.96875 7.00017 2.96875Z" fill="black"/>
                    <path d="M7.00013 5.93945C6.64869 5.93945 6.36377 6.22437 6.36377 6.57582V10.394C6.36377 10.7454 6.64869 11.0304 7.00013 11.0304C7.35158 11.0304 7.6365 10.7454 7.6365 10.394V6.57582C7.6365 6.22437 7.35158 5.93945 7.00013 5.93945Z" fill="black"/>
                  </svg>
                </div>
                <span className={`favourites-header__rules-button-text`}>Rules</span>
              </div>
              {rulesPopUp && <RulesPopUp/>}
            </div>
            {sportData && sportData[0]?.event?.openDate && <div className="sportsPage-headerBet">
                <span>
                  {optimizedDate.slice(0, 10)}
                </span>&nbsp;
                <span>{optimizedDate.slice(11, 20).split(':', 2).join(':')}</span>
              </div>}
            {/* <p className="sportsPage-headerBet">
              {sportData &&
              sportData[0]?.event?.openDate
                .slice(0, 10)
                .replace(/^(\d{4}).(\d{2}).(\d{2})/, '$3.$2.$1')
                .replaceAll('.', '/')}
              &nbsp;{sportData && sportData[0]?.event?.openDate.slice(11, 16)}
            </p> */}
          </div>
          {activeTab === 2 && isLoaded ? (
            <LiveSection
              eventTypeId={eventTypeId}
              eventId={eventId}
              historyVideo={history.location.pathname}
            />
          ) : null}
          {(activeTab === 0 || activeTab === 2) && renderPageContent()}
          {activeTab === 1 && (
            <div className="news">
              {twitterSettings && (
                <Timeline
                  dataSource={{
                    sourceType: twitterSettings['4'].sourceType,
                    slug: twitterSettings['4'].slug,
                    ownerScreenName: twitterSettings['4'].ownerScreenName,
                  }}
                  options={{
                    ...twitterSettings['4'].options,
                    tweetLimit: false,
                  }}
                />
              )}
            </div>
          )}
          {activeTab === 3 && <OpenBets openBets={openBets} isEvent={true} />}
        </div>
      )}
    </>
  )
}

// export default EventPage;
export default connectExchangeGamePage()(ExchangeGamePage)
