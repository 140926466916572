import React, { useState, useEffect } from 'react'
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonHeader
} from '@ionic/react'

import './FavouritesPage.scss'
import { connectFavouritesPage } from './FavouritesPage.connect'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import Toolbar from '../../components/Toolbar/Toolbar'
import LoaderContent from '../../components/LoaderContent/LoaderContent'
import InPlayPopular from '../../components/InPlay/inPlayPopular'
import { GET_INPLAY_POPULAR_INTERVAL } from '../../config/app.config'
import { headerType } from '../../helpers/InPlayPopular.helper'
import { updateFav, updateFavPop, updateFavFuture, updateFavSportMarkets, updateFavoritesAll } from '../../store/favorites/favorites.slice'
import SportPageItem from '../../components/SportPageItem/SportPageItem'
import SportMarketStandard from '../../components/SportMarketStandard/SportMarketStandard';
import { formatTime } from '../../helpers/date.helper'
import FooterDesktop from '../../components/FooterDesktop/FooterDesktop'

const FavouritesPage = ({
  lastActivityDate,
  inplayPopular,
  promotions,
  balanceSettings,
  legalAgePopoverShow,
  kycPopoverShow,
  moreRulesPopoverShow,
  timeSettings,
  betPlacing,
  orderCount,
  actions,
  user,
  sportPageAllEvents,
  elementsParams,
  sportData,
  sportDataMarkets,
  balance,

  ...props
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  // console.log('FavouritesPage', inplayPopular)
  const favorites = useSelector((state) => state.favorites)
  const favoritesState = useSelector((state) => state.favorites.inPlay)
  const favoritesStatePopular = useSelector((state) => state.favorites.popular)
  const favoritesStateFuture = useSelector((state) => state.favorites.future)
  const favoritesStateSportMarkets = useSelector((state) => state.favorites.sportMarkets)
  const favoritesAll = useSelector((state) => state.favorites.favoritesAll)

  // const updatedFavorites = useSelector((state) => state.favorites)

  
  const [counter, setCounter] = useState(1);
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter + 1), 1000);
    return () => clearInterval(timer);
  }, [counter])

  useEffect(() => {
    // if (favoritesStateSportMarkets <= 0) {
    //   localStorage.removeItem('favorites', 'sportMarkets');
    // } else if (favoritesStateFuture <= 0) {
    //   localStorage.removeItem('favorites', 'future');
    // } else if (favoritesStatePopular <= 0) {
    //   localStorage.removeItem('favorites', 'popular');
    // } else if (favoritesState <= 0) {
    //   localStorage.removeItem('favorites', 'inPlay');
    // } else
      if (favoritesAll < 1) {
      localStorage.removeItem('favorites', 'favoritesAll');
    } else {
      return
    }
  }, [favorites]);

  // useEffect(() => {
  //   actions.requestSportPageAllEvents()
  //     // actions.requestSportDataMarkets().then((res) => 
  //     //   setNewSportMarkets(res.data.result)
  //     // )
  //   // actions.requestSportPageEvent(4)
  //   // actions.requestSportPageEvent(2)
  // }, [inplayPopular])
  
  const [newArray, setNewArray] = useState([]);

  useEffect(() => {
    const uniqueFavorites = favoritesAll && favoritesAll.filter((item, index, self) =>
      index === self.findIndex((fav) => fav.match_id === item.match_id)
    );

    favoritesAll && uniqueFavorites.map((item) => {
      // console.log("item.groupById", item.sport_id, item)
        actions.requestSportDataMarkets(item.series_id, item.match_id)
          .then((res) => {

            const mergedFavorites = favoritesAll.map(fav => {
              // Find matching market in the response data
              const matchData = res.data.data.find(match => 
                match.match_id === fav.match_id && match.market_id === fav.market_id
              );
              
              if (matchData) {
                return {
                  ...matchData,
                  // sport_id: fav.sport_id || '4', match_name: "Testing"
                };
              }
              return fav; // Return the original favorite if no match is found
            });
            
            // console.log("mergedFavorites", mergedFavorites);
            setNewArray(mergedFavorites)
            // console.log("item.groupById", favoritesAll, res.data.data)
            // setNewArray(
            //   favoritesAll.map(item => {
            //     const foundItem = res.data.data.find(item2 => item2.market_id === item.market_id);
            //     // If foundItem exists, merge it with the sport_id from item
            //     return foundItem ? { ...foundItem, sport_id: item.sport_id, match_name: "Testing" } : item;
            //   })
            // );
            // setNewArray(favoritesAll.map(item => res.data.data.find(item2 => item2.market_id === item.market_id) || item))
          }
          ).catch((error) => {
            let a = favoritesAll.indexOf(favoritesAll.filter(item2 => item2.market_id === item.market_id)[0])
            // console.log(favoritesStateSportMarkets);
            newArray.length > 0 && newArray.splice(a, 1)
            // console.log('***************');
            setNewArray(favoritesAll.map(item => newArray.find(item2 => item2.market_id === item.market_id) || item))
            // console.log(favoritesStateSportMarkets.indexOf(favoritesStateSportMarkets.filter(item2 => item2.id === item.id)[0]));
            // console.log(newArray);
            if (favoritesAll.length !== newArray.length) {
              dispatch(updateFavoritesAll(newArray))
            }
          })
      })
    
  }, [counter]);


  const inPlayFavourites = () => {
    const leastArr =
      inplayPopular?.inPlayEvents.length < favoritesState.length
        ? inplayPopular?.inPlayEvents
        : favoritesState
    const biggestArr =
      inplayPopular?.inPlayEvents.length >= favoritesState.length
        ? inplayPopular?.inPlayEvents
        : favoritesState

    const resultArray = biggestArr.filter((item) => {
      return leastArr.some((item2) => item2.event.id === item.event.id)
    })
    return resultArray
  }

  const popFavourites = () => {
    const leastArr =
      inplayPopular?.popularEvents.length < favoritesStatePopular.length
        ? inplayPopular?.popularEvents
        : favoritesStatePopular
    const biggestArr =
      inplayPopular?.popularEvents.length >= favoritesStatePopular.length
        ? inplayPopular?.popularEvents
        : favoritesStatePopular

    const resultArray = biggestArr.filter((item) => {
      return leastArr.some((item2) => item2.event.id === item.event.id)
    })
    return resultArray
  }
  
  const futureFavourites = () => {
    const leastArr = favoritesStateFuture
    const biggestArr = sportPageAllEvents ? sportPageAllEvents : favoritesStateFuture

    const resultArray = biggestArr.filter((item) => {
      return leastArr.some((item2) => item2.event.id === item.event.id)
    })
    return resultArray
  }

  const sportMarketsFavourites = () => {
    const leastArr = favoritesStateSportMarkets
    const biggestArr = newArray ? newArray : favoritesStateSportMarkets

    const resultArray = biggestArr.filter((item) => {
      return leastArr.some((item2) => item2.id === item.id)
    })
    return resultArray
  }
  const favouritesAll = () => {
    const leastArr = favoritesAll
    const biggestArr = newArray ? newArray : favoritesAll

    const resultArray = biggestArr.filter((item) => {
      return leastArr.some((item2) => item2.id === item.id)
    })
    return resultArray
  }

  useEffect(() => {
    // dispatch(updateFav(inPlayFavourites()))
    // dispatch(updateFavPop(popFavourites()))
    // dispatch(updateFavFuture(futureFavourites()))
    // if (favoritesStateSportMarkets.length === newArray.length) {
    //     dispatch(updateFavSportMarkets(sportMarketsFavourites()))
    // }
    if (favoritesAll.length === newArray.length) {
        dispatch(updateFavoritesAll(favouritesAll()))
    }
  }, [counter])

  const [displayedBack, setDisplayedBack] = useState(false)
  const pathname = props.localPathnameNow

  // useEffect(() => {
  //   if (history.location.pathname === '/favouritesPage')
  //     actions.requestOrderCount()
  // }, [history.location.pathname, lastActivityDate])

  // const getInplayPopular = () => {
  //   actions
  //     .requestInplayPopular()
  //     .catch((e) => console.log('requestInplayPopular error = ', e))
  // }

  // useEffect(() => {
  //   if (!inplayPopular && history.location.pathname === '/favouritesPage')
  //     getInplayPopular()
  //   else {
      // const timer = setIntervalAsync(async () => {
      //   if (history.location.pathname === '/favouritesPage') await getInplayPopular()
      // }, GET_INPLAY_POPULAR_INTERVAL)
      // return () => clearIntervalAsync(timer)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [inplayPopular, history.location.pathname])



  const width = elementsParams && elementsParams?.clientWidth ? elementsParams?.clientWidth : window.innerWidth

  const timeZoneName = useSelector((state) => state.timeSettings.timeZoneName)
  const getZonedTime = (timezone, time) => {
    let date = time ? new Date(time) : new Date()
    if (timezone === 'system')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
    if (timezone === 'india')
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset() + 330)

    return date
  }
  const tsToDate = (ts, timezone) => {
    const date = getZonedTime(timezone, ts)

    return `${
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate()
    }/${
      (date.getMonth() + 1).toString().length === 1
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${formatTime(
      date.getMinutes()
    )}:${formatTime(date.getSeconds())}`
  }

  
  // id таймера
  // let timerId = null;
  // вычисляем разницу дат и устанавливаем оставшееся времени в качестве содержимого элементов
  function countdownTimer(startDate) {
    // конечная дата
    const deadline = new Date(startDate);
      // склонение числительных
    function declensionNum(num, words) {
      return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? num % 10 : 5]];
    }
    
    const diff = deadline - new Date();
    // if (diff <= 0) {
    //   clearInterval(timerId);
    // }
    const days = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0;
    const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
    const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
    const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0;
    // const daysNum = (days < 10 ? '0' + days : days).toString();
    // console.log(a);
    // const hoursNum = hours < 10 ? '0' + hours : hours;
    // $minutes.textContent = minutes < 10 ? '0' + minutes : minutes;
    // $seconds.textContent = seconds < 10 ? '0' + seconds : seconds;
    const daysText = declensionNum(days, ['day', 'days', 'days']);
    const hoursText = declensionNum(hours, ['hour', 'hours', 'hours']);
    const minutesText = declensionNum(minutes, ['minute', 'minutes', 'minutes']);
    const secondsText = declensionNum(seconds, ['second', 'seconds', 'seconds']);
    return (`${`Opens in ${days === 0 ? '' : days} ${days === 0 ? '' : daysText} ${hours === 0 ? '' : hours} ${hours === 0 ? '' : hoursText} ${minutes === 0 ? '' : minutes} ${minutes === 0 ? '' : minutesText}`}`);
  }
  // вызываем функцию countdownTimer
  // countdownTimer();
  // вызываем функцию countdownTimer каждую секунду
    // timerId = setInterval(countdownTimer(), 1000)

  // const filteredFootball = favoritesAll?.filter((item) => item?.eventTypeId === "1").sort((a, b) => +a?.groupById - +b?.groupById)
  // const filteredFootball2 = [...filteredFootball]
  // const filteredFootball3 = [...filteredFootball2.sort((a, b) => a.start - b.start)]

  const filteredSport = (id) => {
    const filteredSport = favoritesAll?.filter((item) => item?.sport_id === id).sort((a, b) => +a?.market_id - +b?.market_id)
    const filteredSport2 = [...filteredSport]
    return [...filteredSport2.sort((a, b) => a.start - b.start)]
  }

  return (
    <>
      {width < 1024 ? (
        <IonPage>
          <IonHeader>
            <Toolbar />
          </IonHeader>
          {/* <div className="container">
          <div className="container_first">
            <LeftSideMenuDesktop />
          </div>
          <div className="container_sec"> */}
          <IonContent className="default-page__content">
            {inplayPopular && inplayPopular.inPlayEvents ? (
              <>
                <InPlayPopular
                  pathname={pathname}
                  displayedBack={displayedBack}
                  betPlacing={betPlacing}
                  timeSettings={timeSettings}
                  setDisplayedBack={setDisplayedBack}
                  balanceSettings={balanceSettings && balanceSettings.settings}
                  inPlayEvents={favoritesState}
                  type={headerType['INPLAY']}
                  orderCount={(orderCount && orderCount.count) || 0}
                />
                <InPlayPopular
                  displayedBack={displayedBack}
                  betPlacing={betPlacing}
                  timeSettings={timeSettings}
                  setDisplayedBack={setDisplayedBack}
                  balanceSettings={balanceSettings && balanceSettings.settings}
                  inPlayEvents={favoritesStatePopular}
                  type={null}
                />
              </>
            ) : (
              <LoaderContent />
            )}
            {/* <div>Favourites</div> */}
            {/* {width < 1024 && <FooterDesktop />} */}
          </IonContent>
          {/* </div>
          <div className="container_third">
            <BalanceInformation />
            <BetSettingsDesktop />
          </div>
        </div> */}
        </IonPage>
      ) : (
        //   <IonPage>
        // <IonHeader>
        //   <ToolbarDesktop />
        // </IonHeader>
        // <div className="container">
        //   <div className="container_first">
        //     <LeftSideMenuDesktop />
        //   </div>
        //   <div className="container_sec">
        <div className="default-page__content" style={{color: "#000000"}}>
          <h1 className="favourites-header">Favourites</h1>
          {/* {inplayPopular && inplayPopular.inPlayEvents ? (
            <> */}
              {favoritesAll.length <=0  ? (
                <div style={{ marginLeft: '10px' }}>No events added yet</div>
              ) : (
                <>
                  {/* {favoritesState.length > 0 ? (
                    <InPlayPopular
                      pathname={pathname}
                      displayedBack={displayedBack}
                      betPlacing={betPlacing}
                      timeSettings={timeSettings}
                      setDisplayedBack={setDisplayedBack}
                      balanceSettings={
                        balanceSettings && balanceSettings.settings
                      }
                      inPlayEvents={favoritesState}
                      type={headerType['INPLAY']}
                      orderCount={(orderCount && orderCount.count) || 0}
                    />
                  ) : null}

                  {favoritesStatePopular.length > 0 ? (
                    <InPlayPopular
                      displayedBack={displayedBack}
                      betPlacing={betPlacing}
                      timeSettings={timeSettings}
                      setDisplayedBack={setDisplayedBack}
                      balanceSettings={
                        balanceSettings && balanceSettings.settings
                      }
                      inPlayEvents={favoritesStatePopular}
                      type={headerType['POPULAR']}
                    />
                  ) : null}

                  {favoritesStateFuture.length > 0 ? (

                    <InPlayPopular
                      displayedBack={displayedBack}
                      betPlacing={betPlacing}
                      timeSettings={timeSettings}
                      setDisplayedBack={setDisplayedBack}
                      balanceSettings={
                        balanceSettings && balanceSettings.settings
                      }
                      inPlayEvents={favoritesStateFuture}
                      type={headerType['FUTURE']}
                    />
                  ) : null} */}
                      
                  {favoritesAll && favoritesAll.filter((item) => item?.sport_id === "4").length > 0 &&
                  <div className="EventPage">
                    <div className="">
                            <h2 style={{ marginLeft: '14px', marginBottom: '0px' }}>Cricket</h2>
                          </div>
                    <div className="SportEvent">
                      {favoritesAll && filteredSport('4').map((item, index) => 
                        <>
                          {index === 0 || (index !== 0 && filteredSport('4').filter((item) => item.sport_id === "4").sort((a, b) => a.start - b.start)[index - 1].match_id !== item?.match_id) ?
                            <>
                              <div
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignContent: 'center' }} className={`favourites-header__inner-wrapper`}>
                                <h3 style={{ marginLeft: '12px', fontSize: '18px', lineHeight: '20px' }}>
                                  {item?.match_name}
                                </h3>
                                <div className="sportsPage-headerBet">
                                  <span>{tsToDate(item?.match_date, timeZoneName).slice(0, 10)}</span>&nbsp;
                                  <span>{tsToDate(item?.match_date, timeZoneName).slice(11, 20).split(':', 2).join(':')}</span>
                                </div>
                              </div>
                              {item?.inplay !== true && <div className="StartEventTimeCounter">{countdownTimer(item?.match_date)}</div>}
                              
                            </>
                            : null}
                          
                          <SportMarketStandard
                            data={item}
                            key={item.marketId}
                            betPlacing={betPlacing}
                          />
                        </>
                        )}
                    </div>
                  </div>
                }
                
                {favoritesAll  && favoritesAll.filter((item) => item?.sport_id === "1").length > 0 &&
                  <div className="EventPage">
                    <div className="">
                      <h2 style={{ marginLeft: '14px', marginBottom: '0px' }}>Football</h2>
                    </div>
                    <div className="SportEvent">
                        {favoritesAll && filteredSport('1').map((item, index) => 
                        <>
                          {index === 0 || (index !== 0 && filteredSport('1').filter((item) => item.sport_id === "1").sort((a, b) => a.start - b.start)[index - 1].match_id !== item?.match_id) ?
                            <>
                            <div
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignContent: 'center' }} className={`favourites-header__inner-wrapper`}>
                              <h3 style={{ marginLeft: '12px', fontSize: '18px', lineHeight: '20px' }}>
                                {item?.match_name}
                              </h3>
                              <div className="sportsPage-headerBet">
                                <span>{tsToDate(item?.match_date, timeZoneName).slice(0, 10)}</span>&nbsp;
                                <span>{tsToDate(item?.match_date, timeZoneName).slice(11, 20).split(':', 2).join(':')}</span>
                              </div>
                          </div>
                               {item?.inplay !== true && <div className="StartEventTimeCounter">{countdownTimer(item?.match_date)}</div>}
                            </>
                             : null}
                          
                          <SportMarketStandard
                            data={item}
                            key={item.marketId}
                            betPlacing={betPlacing}
                          />
                        </>
                        )}
                    </div>
                  </div>
                }
                {favoritesAll && favoritesAll.filter((item) => item?.sport_id === "2").length > 0 &&
                  <div className="EventPage">
                    <div className="">
                      <h2 style={{ marginLeft: '14px', marginBottom: '0px' }}>Tennis</h2>
                    </div>
                    <div className="SportEvent">
                      {favoritesAll && filteredSport('2').map((item, index) => 
                        <>
                          {index === 0 || (index !== 0 && filteredSport('2').filter((item) => item.sport_id === "2").sort((a, b) => a.start - b.start)[index - 1].match_id !== item?.match_id) ?
                            <>
                            <div
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', alignContent: 'center'}} className={`favourites-header__inner-wrapper`}>
                              <h3 style={{ marginLeft: '12px', fontSize: '18px', lineHeight: '20px'}}>
                                {item?.match_name}
                              </h3>
                              <div className="sportsPage-headerBet">
                                <span>{tsToDate(item?.match_date, timeZoneName).slice(0, 10)}</span>&nbsp;
                                <span>{tsToDate(item?.match_date, timeZoneName).slice(11, 20).split(':', 2).join(':')}</span>
                              </div>
                              </div>
                               {item?.inplay !== true && <div className="StartEventTimeCounter">{countdownTimer(item?.match_date)}</div>}
                            </>
                             : null}
                          
                          <SportMarketStandard
                            data={item}
                            key={item.marketId}
                            betPlacing={betPlacing}
                          />
                        </>
                        )}
                    </div>
                  </div>
                }

                </>
                )}
        </div>
      )}
    </>
  )
}

export default connectFavouritesPage()(FavouritesPage)
