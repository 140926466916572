import React  from 'react'
import './OpenBets.scss'
import { connectOpenBets } from './OpenBets.connect'
import OpenBetsList from '../OpenBetsList/OpenBetsList'
import { toastr } from 'react-redux-toastr'

const OpenBets = ({ timeSettings, openBets, unMatchedBets, redirect, isEvent, actions }) => {

  const cancel = (orderId) => {
    actions
      .requestCancelOrder(orderId)
      .then((res) => {
        if (res.data?.status) {
          actions.requestUnMatchedBets();
          toastr.success('', 'Your bet was canceled successfully.')
        } else {
          toastr.error('', res.data.msg)
        }
      })
      .catch((e) => {
        console.log("Erroe", e)
        if (e.msg)
          toastr.error('', e.msg)
        else toastr.error('', 'Something went wrong')
      })
  }

// console.log('unmatched', openBets?.unmatched);

  const cancelAll = (marketId) => {
    actions
      .requestCancelAllBets(marketId)
      .then((res) => {
        if (res.data?.status) {
          actions.requestUnMatchedBets();
          toastr.success('', 'Your bet was canceled successfully.')
        } else {
          toastr.error('', res.data.msg)
        }
      })
      .catch((e) => {
        if (e.msg)
          toastr.error('', e.msg)
        else toastr.error('', 'Something went wrong')
      })
  }
  // console.log("openBets",unMatchedBets)
  return (
    <section className="OpenBets">
      {openBets ? (
        <>
          <OpenBetsList
            bets={unMatchedBets}
            timeSettings={timeSettings}
            isEvent={isEvent}
            redirect={redirect}
            isUnmatched={true}
            title="Unmatched Bets"
            cancel={cancel}
            cancelAll={cancelAll}
          />
          <OpenBetsList
            bets={openBets}
            timeSettings={timeSettings}
            isEvent={isEvent}
            redirect={redirect}
            title="Matched Bets"
          />
        </>
      ) : null}
    </section>
  )
}

export default connectOpenBets()(OpenBets)
