import './BetPlacingPopover.scss'
import { IonButton, IonPopover, IonSpinner } from '@ionic/react'
import React, { useState, useEffect } from 'react'
import { connectBetPlacingPopover } from './BetPlacingPopover.connect'
import { toastr } from 'react-redux-toastr'
import { getPlacingText } from '../../helpers/betPlacing.helper'
import { createPortal } from 'react-dom'
const modalRoot = document.querySelector('#modal-root')

const BetPlacingPopover = ({
  open,
  betPlacing,
  refBetPopover,
  cancel,
  actions,
  elementsParams,
}) => {
  const width =
    elementsParams && elementsParams?.clientWidth
      ? elementsParams?.clientWidth
      : window.innerWidth

  const [timer, setTimer] = useState(betPlacing.delay)

  useEffect(() => {
    setTimer(betPlacing.delay)
  }, [betPlacing.delay, open])
  //betPlacing.betPlacingPopoverStage2,
  useEffect(() => {
    if (timer && betPlacing.betPlacingPopoverStage2 && open) {
      const time = setInterval(() => {
        setTimer((timer) => timer - 1)
      }, 1000)
      return () => clearInterval(time)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, betPlacing.betPlacingPopoverStage2, open])

  const profitType = () => (betPlacing.isBack ? 'Profit' : 'Liability')

  const profit = () => {
    const isFancy =
      betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE'
    const stake = betPlacing.stake
      ? isFancy
        ? Number((betPlacing.stake * betPlacing.priceActual) / 100).toFixed(2)
        : Number(
            betPlacing.stake * betPlacing.priceActual - betPlacing.stake
          ).toFixed(2)
      : '0.00'
    return stake
  }

  const submit = async () => {
    actions.setBetPlacingPopoverStage(true)
    actions
      .requestSetOrder(betPlacing)
      .then((res) => {
        const text = getPlacingText(betPlacing, res)
        if (text) {
          text.indexOf('Unmatched') === -1
            ? toastr.success('', text)
            : toastr.error('', text)
        }
        cancel()
      })
      .catch((e) => {
        cancel()
        if (e?.msg) toastr.error('', e?.msg)
      })
  }

  const onDismissed = (e) => {
    actions.setIsCashOut(false)
    actions.setCashOutId(null)
    actions.setShowBetPlacingPopover(false)
    actions.setBetPlacingPopoverStage(false)
  }

  const submitCashOut = async () => {
    console.log('Data Bet', betPlacing)
    actions.setBetPlacingPopoverStage(true)
    actions
      .requestSetOrder(betPlacing)
      .then((res) => {
        if (res?.data) {
          if (res?.data?.status) {
            toastr.success('', 'Cash-out successful')
            actions.setIsCashOut(false)
            actions.setCashOutId(null)
          } else {
            toastr.error('', res?.data?.msg)
          }
        }
        actions.requestOpenBets()
        actions.requestUnMatchedBets()

        actions.setShowBetPlacingPopover(false)
        // cancel()
      })
      .catch((e) => {
        // cancel()
        actions.setShowBetPlacingPopover(false)
        if (e[0] && e[0][0] && e[0][0].msg) toastr.error('', e[0][0].msg)
        else toastr.error('', e?.msg)
      })
  }

  const renderOdds = () => {
    if (betPlacing.mtype == 'INNINGS_RUNS' && betPlacing.btype == 'LINE')
      return (
        <>
          <div className="BetPlacing__col BetPlacingPopover__col">
            <div className="BetPlacingPopover__param">RUNS</div>
            <div className="BetPlacingPopover__value">{betPlacing.line}</div>
          </div>
          <div className="BetPlacing__col BetPlacingPopover__col">
            <div className="BetPlacingPopover__param">ODDS (H-J)</div>
            <div className="BetPlacingPopover__value">
              {betPlacing.priceActual}
            </div>
          </div>
        </>
      )
    return (
      <div className="BetPlacing__col BetPlacingPopover__col">
        <div className="BetPlacingPopover__param">ODDS</div>
        <div className="BetPlacingPopover__value">{betPlacing.priceActual}</div>
      </div>
    )
  }

  const renderContent = () => {
    if (betPlacing.betPlacingPopoverStage2)
      return (
        <>
          <div className="BetPlacing__row BetPlacingPopover__timertext">
            Your bet is being processed.
          </div>
          <div className="BetPlacing__row BetPlacingPopover__timertext">
            Please wait...
          </div>
          <div className="BetPlacingPopover__timer">
            <IonSpinner
              name="crescent"
              className="BetPlacingPopover__timer__spinner"
            />
            <div className="BetPlacingPopover__timer__seconds">{timer}</div>
          </div>
        </>
      )
    return betPlacing.isCashOut ? (
      <>
        <div
          className="BetPlacing__row ConfirmBetPopUpp__name"
          style={{ fontSize: '14px' }}
        >
          Do you wish to perform cash out?
        </div>
        <div className="ConfirmBetPopUpp__buttons" style={{ width: 'inherit' }}>
          <div
            className="ConfirmBetPopUpp__buttons-cancel"
            onClick={() => onDismissed()}
          >
            Cancel
          </div>
          <button
            type="submit"
            onClick={submitCashOut}
            className="pushable"
            expand="full"
            mode="md"
          >
            <span className="shadow"></span>
            <span className="edge refreshBtn"></span>
            <span className="front refreshBtn">Cash Out</span>
          </button>
        </div>
      </>
    ) : (
      <>
        <div className="BetPlacing__row BetPlacingPopover__name">
          {betPlacing.eventName}
        </div>
        <div className="BetPlacing__row BetPlacingPopover__runner">
          {betPlacing.isBack ? 'Backing' : 'Laying'}&nbsp;
          <span className="bold">{betPlacing.runnerName}</span>
        </div>
        <div className="BetPlacing__row BetPlacingPopover__params">
          {renderOdds()}
          <div className="BetPlacing__col BetPlacingPopover__col">
            <div className="BetPlacingPopover__param">STAKE</div>
            <div className="BetPlacingPopover__value">{betPlacing.stake}</div>
          </div>
          <div className="BetPlacing__col BetPlacingPopover__col">
            <div className="BetPlacingPopover__param">{profitType()}</div>
            <div className="BetPlacingPopover__value">{profit()}</div>
          </div>
        </div>
        <div className="BetPlacing__row">
          <div className="BetPlacing__col">
            <IonButton
              className="BetPlacing__btn BetPlacing__btn--cancel"
              expand="full"
              type="reset"
              mode="md"
              onClick={cancel}
            >
              Cancel
            </IonButton>
          </div>
          <div className="BetPlacing__col">
            <button
              className="BetPlacing__btn BetPlacing__btn--place"
              expand="full"
              mode="md"
              disabled={
                Number(betPlacing.stake) <= 0 ||
                Number(betPlacing.priceActual) < 1.01
              }
              type="submit"
              onClick={submit}
            >
              <div className="BetPlacing__col">
                <div>Place Bet</div>
                <div className="BetPlacing__profit">
                  {profitType()}: {profit()}
                </div>
              </div>
              <div className="BetPlacing__delay">
                <i className="apl-icon-info-stopwatch" title="delay"></i>
                {betPlacing.delay}s
              </div>
            </button>
          </div>
        </div>
      </>
    )
  }
  if (width >= 1024)
    return (
      <IonPopover
        isOpen={open}
        ref={refBetPopover}
        backdropDismiss={false}
        cssClass={`BetPlacingPopover ${
          betPlacing.isBack
            ? 'BetPlacingPopover--back'
            : 'BetPlacingPopover--lay'
        }`}
        onDidDismiss={onDismissed}
      >
        {renderContent()}
      </IonPopover>
    )
  if (width < 1024)
    return createPortal(
      <div className="betPlacingPop-upContainer">
        <div className="betPlacingPop-up_wrapper">
          <div
            className={`betPlacingPop-up ${
              betPlacing.isBack
                ? 'betPlacingPop-up--back'
                : 'betPlacingPop-up--lay'
            }`}
          >
            <div className="betPlacingPop-up-content">{renderContent()}</div>
          </div>
        </div>
      </div>,
      modalRoot
    )
}

export default connectBetPlacingPopover()(BetPlacingPopover)
