import { createSlice } from '@reduxjs/toolkit'

export const unMatchedBetsSlice = createSlice({
    name: 'unMatchedBets',
    initialState: [],
    reducers: {
        getUnMatchedBetsSuccess: (state, action) => action.payload,
        getUnMatchedBetsError: (state) => [],
        
    },
})

export const {
    getUnMatchedBetsError,
    getUnMatchedBetsSuccess,
    setInfoDetailed,
} = unMatchedBetsSlice.actions

export default unMatchedBetsSlice.reducer
