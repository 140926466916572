import { createSlice } from '@reduxjs/toolkit'

const state = JSON.parse(localStorage.getItem('favorites'))
// const state2 = JSON.parse(sessionStorage.getItem('favorites'))

export const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: {
    inPlay: state?.inPlay ? state?.inPlay : [],
    popular: state?.popular ? state?.popular : [],
    future: state?.future ? state?.future : [],
    sportMarkets: state?.sportMarkets ? state?.sportMarkets : [],
    favoritesAll: state?.favoritesAll ? state?.favoritesAll : [],
  },
  // initialState,
  reducers: {
    // InPlay events
    addfavoritesItem: (state, action) => ({
      ...state,
      favoritesAll: [...state.favoritesAll, action.payload],
    }),
    clearfavoritesItem: (state, action) => ({
      ...state,
      favoritesAll: state.favoritesAll.filter(
        (item) => item.market_id !== action.payload.market_id
      ),
    }),
    updateFav: (state, action) => ({ ...state, inPlay: action.payload }),
    // Popular events
    addfavoritesItemPop: (state, action) => ({
      ...state,
      popular: [...state.popular, action.payload],
    }),
    clearfavoritesItemPop: (state, action) => ({
      ...state,
      popular: state.popular.filter(
        (item) => item.event.id !== action.payload.event.id
      ),
    }),
    updateFavPop: (state, action) => ({ ...state, popular: action.payload }),
    // Future events
    addfavoritesItemFuture: (state, action) => ({
      ...state,
      future: [...state.future, action.payload],
    }),
    clearfavoritesItemFuture: (state, action) => ({
      ...state,
      future: state.future.filter(
        (item) => item.event.id !== action.payload.event.id
      ),
    }),
    updateFavFuture: (state, action) => ({ ...state, future: action.payload }),

    // Sport markets
    addfavoritesSportMarkets: (state, action) => ({
      ...state,
      sportMarkets: [...state.sportMarkets, action.payload],
    }),
    clearfavoritesSportMarkets: (state, action) => ({
      ...state,
      sportMarkets: state.sportMarkets.filter(
        (item) => item.id !== action.payload.id
      ),
    }),
    updateFavSportMarkets: (state, action) => ({ ...state, sportMarkets: action.payload }),

    // Favorites All
    addFavoritesAll: (state, action) => ({
      ...state,
      favoritesAll: [...state.favoritesAll, action.payload],
    }),
    clearFavoritesAll: (state, action) => ({
      ...state,
      favoritesAll: state.favoritesAll.filter(
        (item) => item.id !== action.payload.id
      ),
    }),
    updateFavoritesAll: (state, action) => ({ ...state, favoritesAll: action.payload }),
  },
})

export const {
  addfavoritesItem,
  clearfavoritesItem,
  updateFav,
  addfavoritesItemPop,
  clearfavoritesItemPop,
  updateFavPop,
  addfavoritesItemFuture,
  clearfavoritesItemFuture,
  updateFavFuture,
  addfavoritesSportMarkets,
  clearfavoritesSportMarkets,
  updateFavSportMarkets,
  addFavoritesAll,
  clearFavoritesAll,
  updateFavoritesAll,
} = favoritesSlice.actions

export default favoritesSlice.reducer
