import { getOpenBetsError, getOpenBetsSuccess } from "./openBets.slice";
import getErrorMessage from "../../helpers/getErrorMessage.helper";
import http from "../../services/httpAuth";

export const requestOpenBets = (eventId, sportId) => async (dispatch, getState) => {
    const auth = getState().auth;
    let data = {
        limit: 100,
        page: 1,
        search: sportId == 7 || sportId == 4339 ? {
            delete_status: 0,
            market_id: eventId,
            is_matched: 1
        } :{
            delete_status: 0,
            match_id: eventId,
            is_matched: 1
        }
    }
    if (auth) {
        try {
            // const userId = getState().user.memberCode;
            // const eventParam = eventId ? `&eventId=${eventId}` : "";
            const response = await http.post(`/bet/openBets`, data);
            if (response.data.status) {
                dispatch(getOpenBetsSuccess(response.data.data[0]?.data));
            }
            return response;
        } catch (e) {
            dispatch(getOpenBetsError());
            const errorMsg = getErrorMessage(e);
            throw errorMsg;
        }
    }
};

export const requestOpenBetsWithDate = (from, to, eventId) => async (dispatch, getState) => {
    try {
        const data = {
            limit: 150,
            search: {
                is_matched: 1
            },
            from_date: from,
            to_date: to
        }
        const response = await http.post(`/bet/openBets`, data);
        if (response.data.status) {
            dispatch(getOpenBetsSuccess(response?.data?.data[0]?.data));
        }
        return response;
    } catch (e) {
        dispatch(getOpenBetsError());
        const errorMsg = getErrorMessage(e);
        throw errorMsg;
    }
};

export const requestPastBets = (from, to, eventId) => async (dispatch, getState) => {
    try {
        const data = {
            page: 1,
            limit: 150,
            search: {},
            from_date: from,
            to_date: to
        }
        const response = await http.post(`/bet/settledBets`, data);
        // if (response.data.success) {
        //     dispatch(getOpenBetsSuccess(response.data.result));
        // }
        return response;
    } catch (e) {
        dispatch(getOpenBetsError());
        const errorMsg = getErrorMessage(e);
        throw errorMsg;
    }
};
